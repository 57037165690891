
  
  import {gmapApi} from 'vue2-google-maps'
  import * as geolib from 'geolib';
  
  
  
  export default {
    data() {
      return {
        // Tombol Modal
        tambah: false,
        edit: false,
        lihat: false,
        modal_lokasi: false,
        terima: false,
        tolak: false,
        modal_banding: false,
  
        modal_text : false,
        modal_json : false,
  
     
        // Import JSON
        selectedFile: null,
        isJSONStructureValid: false,
        expectedJSONStructure: [{
          // Define your expected JSON structure here
          // Example: "name": "", "email": "", "age": 0
          "lat": "", "lng": ""
        }],
  
        id : '',
        status : null,
        id_kecamatan : '',

        final : {
          file: '',
        },
       
        form : {
          id: '',
          nik: '',
          nama: '',
          alamat: '',
          kecamatan_id : '',
          nama_kecamatan : '',
          des_kel_id : '',
          nama_des_kel: '',
          catatan: '',
          rwrt: '',
          no_telp: '',
          status_pengajuan: null,
          file: '',
        },
        lokasi: [],
        centroid: null,
        centroid_petadasar: null,
        //
        file_old: "",
        file_path: this.$store.state.url.URL_APP + "uploads/",
  
        no: 0,
        id: '',
        url: {
          GET_URL: this.$store.state.url.URL_APP + "api/v1/monitoring/",
          PETA_FINAL: this.$store.state.url.URL_APP + "api/v1/petafinal/"
         
        },
        list_data: [],
        petadasar: [],
        kecamatan: [],
        desa: [],
  
        page_first: 1,
        page_last: 0,
        cari_value: "",


  
      }
    },
    methods: {

      mappingPetadasar: function(data) {
         var tampung = [];


        for(var i = 0; i < data.length; i++) {
            var lat = data[i][1];
            var lng = data[i][0];

            tampung.push({"lat":parseFloat(lat),"lng":parseFloat(lng)});
        }

        return tampung;


      },
      
      getPetaDasar: function() {

        // console.log("asdasasd :", this.form.kecamatan_id, this.form.des_kel_id);

        fetch(this.url.GET_URL + "petadasar", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
              // kecamatan_id: this.form.kecamatan_id,
              des_kel_id: this.form.des_kel_id
            })
          })
          .then(res => res.json())
          .then(res_data => {


            var hasil = [];
            hasil = res_data[0].data1[0].geometry.coordinates[0];

            

            this.petadasar = hasil;
            
            
            
          });
      },
    
  
  
      mapping: function(data) {
  
        var tampung = [];
  
        for(var i = 0; i < data.length; i++) {
            var obj = data[i];
  
            tampung.push({"lat":parseFloat(obj.lat),"lng":parseFloat(obj.lng)});
        }
  
        return tampung;
  
      },
  
  
      getView: function() {
        this.cek_load_data = true;
        fetch(this.url.GET_URL + "view", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value,
              id : this.id,
              status : this.status,
              id_kecamatan : this.id_kecamatan
            })
          })
          .then(res => res.json())
          .then(res_data => {
  
            
            this.list_data = res_data[0].data1;
            this.page_last = res_data.lengthy;
            this.cek_load_data = false;
            
            // console.log(this.list_data)
            // 
  
  
          });
      },
  
      
      calculatePolygonCentroid : function(data) {
        
        

        const coordinates = tampung.map((point) => ({
          latitude: point.lat,
          longitude: point.lng,
        }));
  
        const centroid = geolib.getCenter(coordinates);

        this.centroid_petadasar = {
          lat: centroid.latitude,
          lng: centroid.longitude,
        };

        // console.log(this.centroid_petadasar);


      },
  
  
  
      terimaData: function() {

        var formData = new FormData();


        formData.append("id", this.form.id);
        formData.append("nik", this.form.nik);
        formData.append("nama", this.form.nama);
        formData.append("alamat", this.form.alamat);
        formData.append("kode_kecamatan", this.form.kecamatan_id);
        formData.append("nama_kecamatan", this.form.nama_kecamatan);
        formData.append("kode_desa", this.form.des_kel_id);
        formData.append("nama_desa", this.form.nama_des_kel);
        formData.append("catatan", this.form.catatan);
        formData.append("rwrt", this.form.rwrt);
        formData.append("no_telp", this.form.no_telp);
        
        formData.append("lokasi", JSON.stringify(this.lokasi));

        formData.append("file", this.final.file);


  
        fetch(this.url.PETA_FINAL + "terimaDataPengajuan", {
          method: "POST",
          headers: {
              authorization: "kikensbatara " + localStorage.token
            },
            body: formData
        }).then(res_data => {
          this.Notify('Sukses Menerima Data', 'primary', 'check_circle_outline');
          this.getView();
          this.terima = false;
        });
      },

      tolakData: function() {
  
        fetch(this.url.GET_URL + "tolakData", {
          method: "POST",
          headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
              id : this.form.id,
              catatan : this.form.catatan,
              
            })
        }).then(res_data => {
          this.Notify('Sukses Menolak Data', 'negative', 'check_circle_outline');
          this.getView();
          this.tolak = false;
        });
      },
  
      selectData: function(data, string) {
  
        if(string == "terima"){
          this.terima = true;
  
          this.form = {
            id: data.id,
            nik: data.nik,
            nama: data.nama,
            alamat: data.alamat,
            kecamatan_id: data.kecamatan_id,
            des_kel_id: data.des_kel_id,
            catatan: data.catatan,
            nama_kecamatan : data.nama_kecamatan,
            nama_des_kel: data.nama_des_kel,
            status_pengajuan: data.status_pengajuan,
            rwrt: data.rwrt,
            no_telp: data.no_telp,
            file: data.file,
          };
          this.lokasi = data.lokasi;
          this.file_old = data.file;
        }
        if(string == "tolak"){
          this.tolak = true;
  
          this.form = {
            id: data.id,
            nik: data.nik,
            nama: data.nama,
            alamat: data.alamat,
            kecamatan_id: data.kecamatan_id,
            des_kel_id: data.des_kel_id,
            catatan: data.catatan,
            nama_kecamatan : data.nama_kecamatan,
            nama_des_kel: data.nama_des_kel,
            status_pengajuan: data.status_pengajuan,
            rwrt: data.rwrt,
            no_telp: data.no_telp,
            file: data.file,
          };
          this.lokasi = data.lokasi;
          this.file_old = data.file;
        }
        else if(string == "lokasi"){

         
          this.modal_lokasi = true;

          this.form = {
            des_kel_id: data.des_kel_id,
            kecamatan_id: data.kecamatan_id,
            nama_kecamatan: data.nama_kecamatan,
            nama_des_kel: data.nama_des_kel,
          }
          
          this.getPetaDasar();
          
  
          
          this.lokasi = data.lokasi;
          this.centroid = data.marker
          
          
        }
        
        else if (string == "lihat") {
          this.lihat = true;
  
          this.form.file = data.file;
          
        }
  
      },

      handlePolygonClick: function(data){

        // var nilai_zona_tanah = this.polygons_zona[index_marker].ambil_nilai.nilai;
        // var zona = this.polygons_zona[index_marker].ambil_nilai.zona;
        // var kecamatan = this.polygons_zona[index_marker].ambil_nilai.kecamatan;

        this.Notify_marker(`Zona ini adalah ${data}`);

        // alert("HAIII "+ this.titik.nama + " dan memiliki harga zona sebesar Rp. "+  nilai_zona_tanah );
      },
  
  
      cari_data: function() {
  
        this.page_first = 1;
        this.getView();
      },
  
      Notify : function(message, positive, icon){
              this.$q.notify({
                message: message,
                color: positive,
                icon: icon,
                position : 'top',
                timeout: 500,
              })
            },

      Notify_marker : function(message){
          this.$q.notify({
            progress: true,
            message: message,
            color: 'success',
            avatar: 'https://cdn.quasar.dev/img/boy-avatar.png',
            position : 'top',
            actions: [
              { label: 'Keluar', color: 'yellow', handler: () => { /* ... */ } }
            ],
            timeout: 10000,
          })
        },
  
   
  
      onFileSelected: function(event) {
        this.form.file = event.target.files[0];
  
      },
  
      btn_prev: function() {
        this.cek_load_data = true;
        if (this.page_first > 1) {
          this.page_first--;
          this.no -= 10;
        } else {
          this.page_first = 1;
         
        }
        this.getView();
      },
  
      btn_next: function() {
  
        if (this.page_first >= this.page_last) {
          this.page_first == this.page_last
        } else {
          this.page_first++;
          this.no += 10;
        }
        this.getView();
      },
  
      addLokasi: function () {
       this.lokasi.push({ lat: 0 , lng: 0});
      },
      hapusLokasi: function () {
       this.lokasi.splice(-1,1);
      }
  
    },
  
    computed: {
      google: gmapApi
    },
    mounted() {
      var get_profile = JSON.parse(localStorage.profile);
        
      this.id = get_profile.id;
      this.status = get_profile.profile.status;
      this.id_kecamatan = get_profile.profile.id_kecamatan;
  
      this.getView();
  
    }
  }
  